<template>
  <div class="image-upload-container"
       :style="getContainerStyle()"
  >
    <Upload v-if="!hasImage"
            class="uploader"
            :action="this.getAPIUrl('image')"
            :headers="headers"
            ref="upload"
            :show-upload-list="false"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
            :on-error="handleError"
            accept="image/*"
            :format="['jpg','jpeg','png']"
            :max-size="10240"
            type="drag"
            :disabled="isLoading"
    >
      <div class="image-upload-inner" v-if="!isLoading">
        <Icon type="ios-camera" size="56"></Icon>
        <p>이미지를 첨부해주세요.</p>
        <p class="small red">{{ imgWidthRatio }}:{{ imgHeightRatio }} 비율의 이미지를 권장합니다.</p>
      </div>
      <div class="image-upload-inner" v-else>
        <Spin fix size="large"></Spin>
      </div>
    </Upload>

    <div class="uploader preview" v-else>
      <div class="image-upload-inner">
        <img :src="url">
        <div class="cover-buttons">
          <div class="icons">
            <div class="icon-wrapper" @click="modalOpen">
              <Icon type="ios-eye-outline"></Icon>
            </div>
            <div class="icon-wrapper" @click="clear">
              <Icon type="ios-trash-outline"></Icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LightBox :toggler="lightboxTrigger" :sources="url"/>
  </div>
</template>

<script>
export default {
  props: {
    ratio: {
      type: String,
      default: '16:9'
    },
    widthRatio: {
      type: Number,
    },
    heightRatio: {
      type: Number,
    },
    url: {
      type: String,
    },
    value: {
      type: [Number, String],
    }
  },
  data() {
    return {
      file: null,
      isLoading: false,
      lightboxTrigger: false,
    }
  },
  computed: {
    hasImage() {
      return !this.isLoading && this.url
    },
    imgWidthRatio() {
      if (this.widthRatio) return this.widthRatio
      return this.ratio.split(':')[0]
    },
    imgHeightRatio() {
      if (this.heightRatio) return this.heightRatio
      return this.ratio.split(':')[1]
    },
    headers() {
      if (this.isLoggedIn) {
        return {Authorization: `Token ${this.$store.getters.authToken}`}
      } else {
        return {}
      }
    },

  },
  methods: {
    clear() {
      this.$emit('input', null)
      this.$emit('update:url', null)
      this.file = null
    },
    modalOpen() {
      this.lightboxTrigger = !this.lightboxTrigger
    },
    beforeUpload() {
      this.isLoading = true
    },
    handleSuccess(res, file) {
      this.isLoading = false
      this.$emit('input', res.id)
      this.$emit('update:url', res.url)
      this.file = file
    },
    handleError(error) {
      this.isLoading = false
    },
    getContainerStyle() {
      return {
        position: 'relative',
        width: "100%",
        paddingTop: `${(this.imgHeightRatio * 100) / this.imgWidthRatio}%`
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.uploader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &:disabled:hover {
    cursor: default;
  }

  &.preview {
    overflow: hidden;

    .image-upload-inner {
      height: 100%;
    }
  }
}

.image-upload-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px;
    background-color: black;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  p {
    font-size: 14px;
    color: rgb(133, 138, 141);
    margin: 8px 0;
    flex: 0 0 auto;
    line-height: 20px;
  }

  .red {
    color: rgb(253, 126, 20);
  }

  .small {
    line-height: 16px;
    font-size: 12px;
  }

  .cover-buttons {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .5);


    .icons {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .icon-wrapper {
      cursor: pointer;
      width: 30%;
      height: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8%;

      &:hover {
        background: rgba(0, 0, 0, .6);
      }
    }

    i {
      color: #fff;
      font-size: 32px;
      cursor: pointer;
    }
  }

  &:hover .cover-buttons {
    display: block;
  }
}


</style>