import { render, staticRenderFns } from "./TabScaffold.vue?vue&type=template&id=042bb3ea&scoped=true&"
import script from "./TabScaffold.vue?vue&type=script&lang=js&"
export * from "./TabScaffold.vue?vue&type=script&lang=js&"
import style0 from "./TabScaffold.vue?vue&type=style&index=0&id=042bb3ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "042bb3ea",
  null
  
)

export default component.exports