import Vue from 'vue'

import Scaffold from "@/layouts/Scaffold";
import Part from "@/layouts/Part";

import PageHeader from "@/components/globals/PageHeader";
import CTable from "@/components/globals/CTable";
import CPaginator from "@/components/globals/CPaginator";
import FilterBox from "@/components/globals/FilterBox";
import BooleanTag from "@/components/globals/BooleanTag";
import CModal from "@/components/globals/CModal";
import StatusTag from "@/components/globals/StatusTag";
import CDatePicker from "@/components/globals/CDatePicker";
import CImageUpload from "@/components/globals/CImageUpload";
import LoadSelect from "@/components/globals/LoadSelect";
import CImage from "@/components/globals/CImage";
import LightBox from "@/components/globals/LightBox";
import CIcon from "@/components/globals/CIcon";
import CButton from "@/components/globals/CButton";
import TabScaffold from "@/layouts/TabScaffold";

// Layout
Vue.component('Scaffold', Scaffold)
Vue.component('Part', Part)
Vue.component('TabScaffold', TabScaffold)

// Component
Vue.component('PageHeader', PageHeader)
Vue.component('BooleanTag', BooleanTag)
Vue.component('CTable', CTable)
Vue.component('CPaginator', CPaginator)
Vue.component('FilterBox', FilterBox)
Vue.component('CModal', CModal)
Vue.component('StatusTag', StatusTag)
Vue.component('CDatePicker', CDatePicker)
Vue.component('CImageUpload', CImageUpload)
Vue.component('LoadSelect', LoadSelect)
Vue.component('CImage', CImage)
Vue.component('LightBox', LightBox)
Vue.component('CIcon', CIcon)
Vue.component('CButton', CButton)
