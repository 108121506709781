<template>
  <div class="tab-item-container">
    <div class="tab-header">
      <div class="l-gap-buttons">
        <slot name="buttons"></slot>
      </div>
    </div>
    <div class="tab-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>

.tab-header {
  margin-bottom: 24px;

  > div {
    display: flex;
    justify-content: flex-end;
  }
}
</style>