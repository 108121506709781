<template>
  <Scaffold>
    <template #buttons>
      <Button @click="() => $router.push({name: 'ticket-bulk'})">대량 생성</Button>
      <Button type="primary" @click="onCreate">추가</Button>
    </template>

    <div class="page-content">
      <CTable
          header-key="ticketList"
          v-bind="tableBinding()"
          @page-change="pageChange"
          @reload="getData"
          v-model="searchParams.search_keyword"
          @on-search="onSearch"
          search-placeholder="ID, 휴대폰 번호, 이름으로 검색"
      >
        <template #username="{row}">
          <router-link :to="{name: 'user-editor', params: {id: row.user_id}}">
            {{row.username}}
          </router-link>
        </template>
        <template #action="{row}">
          <Button size="small" @click="() => onEdit(row)">수정</Button>
          <Button size="small" @click="() => onDelete(row.id)" type="error">삭제</Button>
        </template>
      </CTable>
    </div>
  </Scaffold>
</template>

<script>
import {tableMixin} from "@/mixins/table";

export default {
  name: 'TicketList',
  mixins: [tableMixin],
  computed: {},
  data() {
    return {
      apiUrl: 'ticket',
    }
  },
  methods: {
    onCreate() {
      this.$router.push({name: 'ticket-editor', params: {'id': 'new'}})
    },
    onEdit(row) {
      this.$router.push({name: 'ticket-editor', params: {'id': row.id}})
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style lang="scss" scoped>
</style>
