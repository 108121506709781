export default {
  computed: {
    hasParentRoute() {
      return this.$route.matched.length > 2
    },
    parentRoute() {
      if (this.hasParentRoute) {
        return this.$route.matched[this.$route.matched.length - 2]
      }
      return null
    },
    hasRedirect() {
      return this.$route.query && this.$route.query.redirect
    },
    $redirect() {
      if (this.hasRedirect) {
        return this.$route.query.redirect
      } else {
        return null;
      }
    },
  },
  methods: {
    redirectOrReplace(route) {
      if (this.hasRedirect) {
        return this.$router.replace(this.$redirect)
      } else {
        return this.$router.replace(route)
      }
    },
    redirectToLogin() {
      this.$router.replace({name: 'login', query: {redirect: this.$route.fullPath}})
    },
    pushToTarget(to, target) {
      const r = this.$router.resolve(to)
      window.open(
          r.href, target
      )
    },
    pushToBlank(to) {
      this.pushToTarget(to, '_blank')
    }
  }
}