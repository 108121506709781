<template>
  <i :class="getClass()"></i>
</template>

<script>
  export default {
    props: {
      name: {
        type: String, required: true
      },
    },
    data() {
      return {
        pack: null,
      }
    },
    mounted() {
      if (this.name.search('fa\-') === 0) {
        this.pack = `fas`
      }
    },
    methods: {
      getClass() {
        return `${this.pack} ${this.name}`
      }
    }
  }
</script>

<style scoped lang="scss"></style>