<template>
  <Scaffold>
    <template #buttons>
      <Button @click="() => $router.back()">뒤로</Button>
      <Button type="primary" @click="onCreate">추가</Button>
    </template>

    <div class="page-content">
      <CTable
          header-key="examList"
          v-bind="tableBinding()"
          hide-paginator
      >
        <template #action="{row, index}">
          <div class="r-gap-buttons">
            <Button :loading="loading" size="small"
                    :disabled="index === 0"
                    @click="() => onExamMove(index, -1)">
              <CIcon name="fa-chevron-up"></CIcon>
            </Button>
            <Button :loading="loading" size="small"
                    :disabled="index === items.length - 1"
                    @click="() => onExamMove(index, 1)">
              <CIcon name="fa-chevron-down"></CIcon>
            </Button>
            <Button size="small" type="warning"
                    @click="() => onEdit(row.id)">수정</Button>
            <Button :loading="loading" size="small" type="error" @click="() => onDelete(row.id)">삭제</Button>
          </div>
        </template>
      </CTable>
    </div>
  </Scaffold>
</template>

<script>
import {tableMixin} from "@/mixins/table";

export default {
  mixins: [tableMixin],
  props: ['stepId'],
  data() {
    return {
      apiUrl: `lecture/step/${this.stepId}/exam`
    }
  },
  methods: {
    onCreate() {
      this.loading = true;
      const index = this.items.length
      this.$axios.$post(this.apiUrl, {index: index}).then(res => {
        this.$router.push({name: 'exam-editor', params: {id: res.result.id} })
      }).finally(_ => {
        this.loading = false;
      })
    },
    onEdit(id) {
      this.$router.push({name: 'exam-editor', params: {id: id} })
    },
    onExamMove(index, n) {
      const s = this.items[index].id
      const t = this.items[index + n].id

      this.loading = true
      this.$axios.$post(`lecture/step/${this.stepId}/exam/${s}/index`, {target_id: t}).then(res => {
        this.getData()
      }).finally(_ => {
        this.loading = false
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
</style>
