<template>
  <Scaffold>
    <template #buttons>
      <Button @click="onBack">뒤로</Button>
      <Button type="primary" @click="onSubmit">저장</Button>
    </template>

    <div class="page-content">
      <Form :label-width="120" label-position="right">
        <FormItem label="질문">
          <Input v-model="form.question" type="textarea" :rows="3" size="large"></Input>
        </FormItem>
        <FormItem label="정답">
          <Select v-model="form.answer" size="large">
            <Option v-for="(option, index) in form.options" :value="index" :key="`answer-option-${index}`">
              {{index + 1}}. {{option}}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="보기">
          <Button @click="addOption">추가</Button>
          <div class="options" style="margin-top: 16px;">
            <Input v-for="(v, index) in form.options"
                   v-model="form.options[index]"
                   style="margin-bottom: 12px;"
                   :key="`option-input-${index}`"
                   >
              <span slot="prepend">{{index + 1}}. </span>
              <Button slot="append" @click="() => removeOption(index)">제거</Button>
            </Input>
          </div>
        </FormItem>
      </Form>
    </div>
  </Scaffold>
</template>

<script>
import {editorMixin} from "@/mixins/editor";

export default {
  mixins: [editorMixin],
  props: ['id'],
  computed: {
    apiUrl() {
      return `exam/${this.id}`
    }
  },
  data() {
    return {
      form: {
        question: '',
        options: [],
        answer: '',
      }
    }
  },
  methods: {
    addOption() {
      this.form.options.push('')
    },
    removeOption(index) {
      this.form.options.splice(index, 1)
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
</style>
