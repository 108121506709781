<template>
  <Select :size="size" :value="value" @input="onChange" :loading="loading">
    <Option v-for="i in loadData"
            :value="i[valueKey]"
            :key="`load-option-${i[valueKey]}`"
    >{{i[labelKey]}}</Option>
  </Select>
</template>

<script>
export default {
  props: {
    value: {},
    size: {type: String, default: 'default'},
    apiUrl: {type: String, required: true},
    labelKey: {type: String, default: 'label'},
    valueKey: {type: String, default: 'value'},
    disabled: {type: Boolean, default: false},
    params: {type: Object}
  },
  data() {
    return {
      loadData: [],
      loading: false,
    }
  },
  computed: {
    queryParams() {
      if (this.exists(this.params)) {
        return this.params
      } else {
        return {}
      }
    }
  },
  methods: {
    onChange(val) {
      this.$emit('input', val)
    },
    getData() {
      this.loading = true
      this.$axios.$get(this.apiUrl, {params: this.queryParams}).then(res => {
          this.loadData = res.result
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
</style>