<template>
  <div class="login-page">
    <div class="login-card">
      <Card>
        <div class="card-body">
          <div class="form-title">로그인</div>
          <Form class="login-form">
            <FormItem>
              <Input placeholder="아이디"
                     name="id"
                     size="large"
                     prefix="ios-contact"
                     v-model="form.username"
                     @on-enter="loginSubmit"
              ></Input>
            </FormItem>
            <FormItem>
              <Input placeholder="비밀번호"
                     name="password"
                     size="large"
                     type="password"
                     prefix="ios-lock"
                     v-model="form.password"
                     @on-enter="loginSubmit"
              ></Input>
            </FormItem>
          </Form>
          <Button
              long type="primary"
              :loading="loading"
              size="large"
              @click="loginSubmit"
          >
            <strong>로그인</strong>
          </Button>
        </div>
      </Card>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      valid: {
        username: {presence: true},
        password: {presence: true},
      },
      loading: false,

    }
  },
  methods: {
    loginSubmit() {
      this.loading = true
      this.$axios.$post('auth/login', this.form).then(res => {
        this.$store.commit('setUser', res.result)
        this.redirectOrReplace({name: 'dashboard'})
      }).finally(_ => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.login-page {
  height: 100%;
  background-color: #515a6e;
  padding-top: 260px;

  .login-card {
    max-width: 480px;
    margin: auto;
  }

  .form-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 40px;
  }

  .login-form {
    margin-bottom: 48px;
  }
}
</style>