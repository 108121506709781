<template>
  <div class="error-container">
    <div class="error-title">{{ title }}</div>
    <div class="error-desc">{{ desc }}</div>
    <div class="error-buttons">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {required: true, type: String},
    desc: String,
  }
}
</script>

<style lang="scss" scoped>

.error-container {
  text-align: center;
  padding: 36px;
}
.error-title {
  margin-bottom: 24px;
  font-size: 22px;
  font-weight: bold;
}

.error-desc {

}

.error-buttons {
  margin-top: 46px;
}


</style>
