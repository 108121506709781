export default {
  data() {
    return {
      messages: {
        send: (text, type, duration = 15) => {
          this.$Message[type]({
            content: text,
            duration: duration,
            closable: true
          })
        },
        success: (text, duration = 15) => {
          this.messages.send(text, 'success', duration)
        },
        error: (text, duration = 15) => {
          this.messages.send(text, 'error', duration)
        },
        info: (text, duration = 15) => {
          this.messages.send(text, 'info', duration)
        },
        loading: (text, duration = 15) => {
          this.messages.send(text, 'loading', duration)
        },
        warning: (text, duration = 15) => {
          this.messages.send(text, 'warning', duration)
        },
      }
    }
  },
  computed: {
    isLoggedIn() {
      return !!this.$store.getters.authToken
    },
    $query() {
      return this.$route.query
    },
  },
  methods: {
    copyToClipboard(val) {
      var t = document.createElement("textarea");
      document.body.appendChild(t);
      t.value = val;
      t.select();
      document.execCommand('copy');
      document.body.removeChild(t);
      this.message.success('복사되었습니다.')
    },
    getAPIUrl(path, root = 'admin') {
      return `${process.env.VUE_APP_BASE_API}/${root}/${path}`
    },
    exists(some) {
      if (!some) {
        return false
      } else if (typeof some === 'string' || some instanceof Array) {
        if (some.length === 0)
          return false
      } else if (some instanceof Object) {
        if (Object.keys(some).length === 0)
          return false
      }
      return true
    },
    dig(p, o) {
      return p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
    },
    stringTrim(str, length) {
      if (!str) {
        return null
      } else if (str.length >= length) {
        return str.substring(0, length) + "...";
      } else {
        return str;
      }
    },
    checkRequired(form, requires) {
      return this._.filter(requires, (item) => {
        const member = form[item]
        return !(member === false || member)
      }).length === 0
    },
    logout() {
      this.$store.commit('setUser', null)
      this.$router.replace({name: 'login'})
    },
    deleteConfirm(onConfirm) {
      this.$Modal.confirm({
        title: '삭제 전 확인',
        content: '<p>삭제 시, 돌이킬 수 없습니다. 정말로 삭제하시겠습니까?</p><p>* 해당 작업은 어드민 계정만 가능합니다.</p>',
        onOk: onConfirm,
        okText: '삭제',
        cancelText: '취소',
        width: 480,
      })
    },
    $withoutWatchers(cb) {
      const watchers = this._watchers.map((watcher) => ({cb: watcher.cb, sync: watcher.sync}))

      for (let index in this._watchers) {
        this._watchers[index] = Object.assign(this._watchers[index], {cb: () => null, sync: true})
      }

      cb()

      for (let index in this._watchers) {
        this._watchers[index] = Object.assign(this._watchers[index], watchers[index])
      }
    }
  }
}