<template>
  <Scaffold>
    <template #buttons>
      <Button @click="onBack" :disabled="loading">뒤로</Button>
      <Button @click="onSubmit" type="primary" :disabled="loading">저장</Button>
    </template>

    <div class="page-content">
      <Part title="기본 정보">
        <Form :label-width="120" label-position="right">
          <FormItem label="썸네일">
            <div style="max-width: 360px">
              <CImageUpload
                  v-model="form.thumbnail_id"
                  :url.sync="form.thumbnail"
                  ratio="16:9"
              ></CImageUpload>
            </div>
          </FormItem>

          <FormItem label="제목">
            <Input v-model="form.title" size="large"></Input>
          </FormItem>
          <FormItem label="짧은 설명">
            <Input :rows="3" type="textarea" v-model="form.desc" size="large"></Input>
          </FormItem>
          <FormItem label="내용 요약">
            <Input :rows="6" type="textarea" v-model="form.summary" size="large"></Input>
          </FormItem>
          <FormItem label="공개">
            <Checkbox v-model="form.is_open" size="large">해당 회차를 공개합니다.</Checkbox>
          </FormItem>
        </Form>
      </Part>
      <Part title="영상">
        <Form :label-width="120" label-position="right">
          <FormItem label="영상 키">
            <Row :gutter="16">
              <Col :span="6">
                <Input v-model="form.video_key" size="large" placeholder="영상 고유 키"></Input>
              </Col>
              <Col :span="4">
                <Button @click="crawlVideoData" :loading="loading" type="warning">크롤링</Button>
              </Col>
            </Row>
          </FormItem>
          <FormItem label="호스팅">
            <Select v-model="form.video_provider" size="large">
              <Option value="vimeo">비메오</Option>
            </Select>
          </FormItem>
          <FormItem label="이름">
            <Input v-model="form.video_name" size="large"></Input>
          </FormItem>
          <FormItem label="길이(초)">
            <Input v-model="form.duration" size="large"></Input>
          </FormItem>
        </Form>
      </Part>
      <Part title="해설">
        <template #buttons>
          <Button type="primary" @click="addCommentary">추가</Button>
        </template>

        <div class="part-content">
          <div class="commentary-item" v-for="(c, i) in form.commentary">
            <div class="prefix">
              <div class="index">{{ i + 1 }}</div>
              <div class="buttons">
                <Button size="small" long @click="move(i, -1)" :disabled="i === 0">
                  <CIcon name="fa-chevron-up"></CIcon>
                </Button>
                <Button type="error" long size="small" @click="() => form.commentary.splice(i, 1)">제거</Button>
                <Button size="small" long @click="move(i, +1)" :disabled="i === form.commentary.length -1">
                  <CIcon name="fa-chevron-down"></CIcon>
                </Button>
              </div>
            </div>
            <div class="item-body">
              <div class="row">
                <Input placeholder="제목"
                       v-model="form.commentary[i].title"></Input>
              </div>
              <div class="row">
                <Row :gutter="24">
                  <Col :span="20">
                    <Input placeholder="부제목"
                           v-model="form.commentary[i].subtitle"></Input>
                  </Col>
                  <Col :span="4">
                    <Input placeholder="시점(초)"
                           v-model="form.commentary[i].duration"></Input>
                  </Col>
                </Row>
              </div>
              <div class="row">
                <Input
                    v-model="form.commentary[i].body"
                    placeholder="본문" type="textarea" :rows="3"
                ></Input>
              </div>
            </div>
          </div>
        </div>
      </Part>
    </div>
  </Scaffold>
</template>

<script>
import {editorMixin} from "@/mixins/editor";

export default {
  props: ['id'],
  mixins: [editorMixin],
  data() {
    return {
      form: {
        lecture_id: this.id,
        thumbnail_id: null,
        title: '',
        desc: '',
        is_open: false,
        video_name: '',
        video_key: null,
        duration: null,
        summary: '',
        commentary: [],
        video_provider: 'vimeo',
      },
      commentaryForm: {
        title: '',
        subtitle: '',
        body: '',
        duration: null,
      }
    }
  },
  computed: {
    apiUrl() {
      return `lecture/step/${this.id}`
    }
  },
  methods: {
    crawlVideoData() {
      if (!this.form.video_key) {
        this.messages.error('영상 키를 입력하세요')
        return
      }
      this.loading = true
      this.$axios.$post(`video/crawl`, {
        key: this.form.video_key, provider: this.form.video_provider
      }).then(res => {
        this.form.video_name = res.result.name
        this.form.duration = res.result.duration
      }).finally(_ => {
        this.loading = false
      })
    },
    createLectureVideo() {
      this.pushToBlank({name: 'video-editor', params: {id: 'new'}})
    },
    addCommentary() {
      this.form.commentary.push(Object.assign({}, this.commentaryForm))
    },
    move(index, n) {
      if (n !== 0) {
        const targetItem = this.form.commentary[index]
        const swapItem = this.form.commentary[index + n]
        this.form.commentary[index] = Object.assign({}, swapItem)
        this.$set(this.form.commentary, index + n, Object.assign({}, targetItem))
        this.move(index + n, n > 0 ? n - 1 : n + 1)
      }
    }
  },
  mounted() {
    if (this.isModify) {
      this.getData()
    } else {
      this.addCommentary()
    }
  }
}
</script>

<style lang="scss" scoped>
.commentary-item {
  padding: 12px;
  border: 1px solid #777;
  border-radius: 8px;
  margin-bottom: 12px;
  display: flex;

  .prefix {
    width: 65px;
    margin-right: 24px;

    .index {
      margin-bottom: 24px;
      text-align: center;
      font-size: 24px;
    }

    .buttons {
      text-align: center;

      button {
        margin-bottom: 12px;
      }

    }

  }

  .item-body {
    flex: 1;
  }

  .row {
    margin-bottom: 8px;
  }
}
</style>