<template>
  <Scaffold>
    <template #buttons>
      <Button @click="onBack" :disabled="loading">뒤로</Button>
      <Button :to="{name: 'lecture-step-list', params: {lectureId: id}}" type="warning" :disabled="loading">회차 관리
      </Button>
      <Button @click="onSubmit" type="primary" :disabled="loading">저장</Button>
    </template>

    <div class="page-content">
      <Form label-position="right" :label-width="120">
        <FormItem label="썸네일">
          <div style="max-width: 360px">
            <CImageUpload
                v-model="form.thumbnail_id"
                :url.sync="form.thumbnail"
                ratio="16:9"
            ></CImageUpload>
          </div>
        </FormItem>

        <FormItem label="제목">
          <Input placeholder="제목" size="large" v-model="form.title"></Input>
        </FormItem>
        <FormItem label="강사">
          <Input placeholder="강사" size="large" v-model="form.teacher"></Input>
        </FormItem>
        <FormItem label="학습 목표">
          <Input type="textarea" :rows="3" placeholder="학습 목표" size="large" v-model="form.goal"></Input>
        </FormItem>
        <FormItem label="설명">
          <Input type="textarea" :rows="3" placeholder="클래스에 대한 짧은 설명" size="large" v-model="form.desc"></Input>
        </FormItem>


        <FormItem label="공개 설정">
          <Checkbox size="large" v-model="form.is_open">해당 클래스를 공개합니다.</Checkbox>
        </FormItem>

        <FormItem label="공개 기간">
          <Row>
            <Col span="12">
              <CDatePicker size="large"
                           placeholder="공개 시작일"
                           type="datetime"
                           v-model="form.start_at"
              ></CDatePicker>
            </Col>
            <Col span="12">
              <CDatePicker size="large"
                           placeholder="공개 종료일"
                           type="datetime"
                           v-model="form.end_at"
              ></CDatePicker>
            </Col>

          </Row>
        </FormItem>
      </Form>
    </div>
  </Scaffold>
</template>

<script>
import {editorMixin} from "@/mixins/editor";

export default {
  mixins: [editorMixin],
  props: ['id'],
  data() {
    return {
      form: {
        lecture_id: this.lecture_id,
        thumbnail_id: null,
        title: '',
        desc: '',
        goal: '',
        teacher: '',
        is_open: false,
        start_at: null,
        end_at: null,
      }
    }
  },
  computed: {
    apiUrl() {
      return `lecture/${this.id}`
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
</style>
