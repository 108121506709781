<template>
  <Scaffold>
    <div class="page-content">
      <div class="dashboard">
        <div class="card">
          <div class="title">금일 매출액</div>
          <div class="body">{{dashboard.today_price | addComma}}원</div>
        </div>
        <div class="card">
          <div class="title">총 매출액</div>
          <div class="body">{{dashboard.total_price | addComma}}원</div>
        </div>
      </div>
    </div>
  </Scaffold>
</template>

<script>
export default {
  name: 'Dashboard',
  components: {
  },
  data() {
    return {
      dashboard: {},
      loading: true
    }
  },
  methods: {
    getData() {
      this.loading = true
      this.$axios.$get('dashboard/data').then(res => {
        this.dashboard = res.result
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>


<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex-wrap: wrap;
}

.card {
  padding: 24px;
  border: 1px solid #e8eaec;
  margin: 16px;

  .title {
    font-size: 20px;
    margin-bottom: 12px;
    color: #2d8cf0;
    font-weight: bold;
  }

  .body {
    text-align: right;
    font-size: 16px;
  }
}
</style>