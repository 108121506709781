<template>
  <div class="image-container" :style="getContainerStyle()">
    <div class="wrapper" :style="wrapperStyle()">
      <img :src="src" :alt="alt" v-if="!noImage" @error="onImageLoadError">
      <slot name="empty" v-else>
        <div class="no-img">
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
    },
    alt: {
      type: String
    },
    width: {
      type: [String, Number],
      default: 'auto',
    },
    height: {
      type: [String, Number],
      default: 'auto',
    },
    ratio: {
      type: String,
    },
    widthRatio: {
      type: [String, Number],
    },
    heightRatio: {
      type: [String, Number],
    },
    borderRadius: {
      type: String,
    },

  },
  data() {
    return {
      imageError: false,
      imageSrc: this.src,
      defaultImage: 'https://via.placeholder.com/150/eeeeee.jpg?text= &'
    }
  },
  computed: {
    noImage() {
      return this.imageError || !this.src;
    },
    xRatio() {
      if (this.widthRatio) {
        return this.widthRatio
      } else if (this.ratio) {
        return this.ratio.split(':')[0]
      }
      return null
    },
    yRatio() {
      if (this.heightRatio) {
        return this.heightRatio
      } else if (this.ratio) {
        return this.ratio.split(':')[1]
      }
      return null
    },
  },
  methods: {
    onImageLoadError() {
      this.imageError = true;
    },
    getHeight() {
      if (this.height !== 'auto') {
        return this.height
      } else {
        if (this.yRatio) {
          if (this.width !== 'auto')
            return `calc(${this.width} / ${this.xRatio} * ${this.yRatio})`
        }
        return '100%'
      }
    },
    getWidth() {
      if (this.width !== 'auto') {
        return this.width;
      } else {
        if (this.xRatio) {
          if (this.height !== 'auto')
            return `calc(${this.height} / ${this.yRatio} * ${this.xRatio})`
        }
        return '100%'
      }
    },
    getContainerStyle() {
      if (this.width === 'auto') {
        return {
          paddingRight: this.getWidth(),
          height: this.getHeight()
        }
      } else if (this.height === 'auto') {
        return {
          width: this.getWidth(),
          paddingTop: this.getHeight()
        }
      } else {
        return {
          width: this.getWidth(),
          height: this.getHeight(),
        }
      }
    },
    wrapperStyle() {
      const style = {}
      style['borderRadius'] = this.borderRadius
      return style
    }
  }
}
</script>

<style lang="scss" scoped>
.image-container {
  display: inline-block;
  position: relative;

  .wrapper {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }

  .no-img {
    width: 100%;
    height: 100%;
    background-color: rgb(238, 238, 238);
  }
}
</style>