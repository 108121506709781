<template>
  <Scaffold>
    <template #buttons>
      <Button @click="() => $router.back()">뒤로</Button>
    </template>

    <div class="page-content">
      <div class="extra-info">
        <div class="label">
          클래스 별 현황
        </div>
        <div class="extra-item" v-for="e in extra">
          <div class="lecture-title">{{ e.title }}:</div>
          <div class="count">{{ e.watched_step_count }}/{{ e.total_step_count }}</div>
        </div>
      </div>

      <CTable
          header-key="watchedList"
          v-bind="tableBinding()"
          @page-change="pageChange"
          @reload="getData"
      >
        <template #action="{row}">
          <Button size="small" :to="{name: 'user-editor', params: {id: row.id}}">수정</Button>
        </template>
      </CTable>
    </div>
  </Scaffold>
</template>

<script>
import {tableMixin} from "@/mixins/table";

export default {
  mixins: [tableMixin],
  props: ['id'],
  computed: {
    apiUrl() {
      return `user/${this.id}/watched`
    }
  },
  data() {
    return {
      extra: [],
    }
  },
  methods: {
    getExtraData() {
      this.$axios.$get(`user/${this.id}/refund`).then(res => {
        this.extra = res.result
      })
    }
  },
  mounted() {
    this.getData()
    this.getExtraData()
  }
}
</script>

<style lang="scss" scoped>
.extra-info {
  border: 1px solid black;
  padding: 24px;
  margin-bottom: 48px;

  .label {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .extra-item {
    display: flex;

    .lecture-title {
      font-weight: bold;
    }

    .count {
      margin-left: 8px;
    }
  }
}
</style>