<template>
  <Scaffold>
    <template #buttons>
      <Button @click="onBack" :disabled="loading">뒤로</Button>
      <Button @click="onSubmit" type="primary" :disabled="loading">저장</Button>
    </template>

    <div class="page-content">
      <Form label-position="right" :label-width="120">
        <FormItem label="이름">
          <Input size="large" v-model="form.name"></Input>
        </FormItem>
        <FormItem label="구분">
          <Select size="large" v-model="form.keyword">
            <Option value="private">개인정보처리방침</Option>
            <Option value="service">이용약관</Option>
            <Option value="refund">환불약관</Option>
            <Option value="marketing">마케팅</Option>
          </Select>
        </FormItem>
        <FormItem label="버전">
          <Input size="large" placeholder="높을 수록 우선 적용" v-model="form.version"></Input>
        </FormItem>

        <FormItem label="공개 설정">
          <Checkbox size="large" v-model="form.is_open">해당 약관을 공개합니다.</Checkbox>
        </FormItem>
        <FormItem label="공개 기간">
          <Row>
            <Col span="12">
              <CDatePicker size="large"
                           placeholder="공개 시작일"
                           type="datetime"
                           v-model="form.start_at"
              ></CDatePicker>
            </Col>
            <Col span="12">
              <CDatePicker size="large"
                           placeholder="공개 종료일"
                           type="datetime"
                           v-model="form.end_at"
              ></CDatePicker>
            </Col>
          </Row>
        </FormItem>

        <FormItem label="본문">
          <HtmlEditor v-model="form.body"></HtmlEditor>
        </FormItem>
      </Form>
    </div>
  </Scaffold>
</template>

<script>
import {editorMixin} from "@/mixins/editor";
import HtmlEditor from "@/components/HtmlEditor";

export default {
  components: {HtmlEditor},
  props: ['id'],
  mixins: [editorMixin],
  computed: {
    apiUrl() {
      if (this.isModify) {
        return `policy/${this.id}`
      } else {
        return `policy`
      }
    }
  },
  data() {
    return {
      form: {
        name: '',
        keyword: '',
        version: 1,
        is_open: false,
        start_at: null,
        end_at: null,
      },
    }
  },
  mounted() {
    if (this.isModify) {
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>