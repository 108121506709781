<template>
  <Button
      :size="size" :type="type"
      :long="long" :loading="loading"
      :shape="shape" :disabled="disabled"
      :icon="icon" :ghost="ghost"
      @click="onClick">
    <slot></slot>
  </Button>
</template>

<script>
export default {
  props: {
    target: String,
    to: Object,
    size: String,
    type: String,
    shape: String,
    loading: Boolean,
    disabled: Boolean,
    icon: String,
    long: {type: Boolean, default: false},
    ghost: {type: Boolean, default: false},
  },
  data() {
    return {}
  },
  methods: {
    onClick() {
      if (this.to) {
        if (this.target) {
          this.pushToTarget(this.to, this.target)
        } else {
          this.$router.push(to)
        }
      } else {
        this.$emit('click')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>