<template>
  <Scaffold>
    <template #buttons>
      <Button type="primary" @click="onCreate">추가</Button>
    </template>

    <div class="page-content">
      <CTable
          header-key="productList"
          v-bind="tableBinding()"
          @reload="getData"
      >
        <template #action="{row}">
          <Button size="small" type="warning"
                  @click="() => onEdit(row.id)">수정
          </Button>
        </template>
      </CTable>
    </div>
  </Scaffold>
</template>

<script>
import {tableMixin} from "@/mixins/table";

export default {
  mixins: [tableMixin],
  computed: {},
  data() {
    return {
      apiUrl: 'product'
    }
  },
  methods: {
    onCreate() {
      this.$router.push({name: 'product-editor', params: {id: 'new'}})
    },
    onEdit(id) {
      this.$router.push({name: 'product-editor', params: {id}})
    },
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
</style>