import Vue from "vue";

const filters = {
  addComma(val) {
    if (!val) return val;
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
}

Vue.filter('addComma', filters.addComma)
