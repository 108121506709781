<template>
  <ErrorLayout :title="title" :desc="desc">
    <template #buttons>
      <Button @click="() => $router.back()">돌아가기</Button>
    </template>
  </ErrorLayout>
</template>

<script>
import ErrorLayout from "@/layouts/ErrorLayout";
export default {
  components: {ErrorLayout},
  data() {
    return {
      title: 'Invalid Role',
      desc: '권한이 없습니다.'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>