<template>
  <Scaffold>
    <template #buttons>
      <Button @click="onBack">뒤로</Button>
      <Button type="primary" @click="onSubmit">저장</Button>
    </template>

    <div class="page-content">
      <Form label-position="right" :label-width="120">
        <FormItem label="유저 계정">
          <Input size="large" v-model="form.username" placeholder="휴대폰 번호"/>
        </FormItem>
        <FormItem label="클래스 ID">
          <Input size="large" v-model="form.lecture_id"/>
        </FormItem>
        <FormItem label="결제정보 ID">
          <Input size="large" v-model="form.payment_id" placeholder="없다면 비어두기"/>
        </FormItem>
        <FormItem label="상태">
          <Select v-model="form.status" size="large">
            <Option :value="0">정상</Option>
            <Option :value="-10">환불됨</Option>
            <Option :value="-100">취소됨</Option>
          </Select>
        </FormItem>
        <FormItem label="수강 기간">
          <Row :gutter="16">
            <Col span="8">
              <CDatePicker size="large"
                           placeholder="시작일"
                           type="datetime"
                           v-model="form.start_at"
              ></CDatePicker>
            </Col>
            <Col span="8">
              <CDatePicker size="large"
                           placeholder="종료일"
                           type="datetime"
                           v-model="form.end_at"
              ></CDatePicker>
            </Col>
            <Col span="4">
              <div class="r-gap-buttons">
                <Button @click="() => editYear(1)">+1년</Button>
                <Button type="error" @click="() => editYear(-1)">-1년</Button>
              </div>
            </Col>
          </Row>
        </FormItem>
      </Form>
    </div>
  </Scaffold>
</template>

<script>
import {editorMixin} from "@/mixins/editor";

export default {
  props: ['id'],
  mixins: [editorMixin],
  computed: {
    apiUrl() {
      return this.isModify ? `ticket/${this.id}` : 'ticket'
    }
  },
  data() {
    return {
      form: {
        username: '',
        lecture_id: null,
        start_at: null,
        end_at: null,
        payment_id: null,
        issue_type: 'admin',
        status: 0,
      }
    }
  },
  methods: {
    editYear(val) {
      let date;
      if (this.form.end_at) {
        date = this.$moment(this.form.end_at)
      } else if (this.form.start_at) {
        date = this.$moment(this.form.start_at)
      } else {
        date = this.$moment()
      }
      date.add(val, 'years')
      this.form.end_at = date.format('YYYY-MM-DD HH:mm')
    },
  },
  mounted() {
    if (this.isModify) this.getData()
  }
}
</script>

<style lang="scss" scoped>
</style>