export default {
  methods: {
    initAxios() {
      this.$axios.defaults.baseURL = `${process.env.VUE_APP_BASE_API}/admin`
      if (process.env.NODE_ENV === 'production') {
        this.$axios.defaults.timeout = 50000
      }

      this.$axios.interceptors.request.use((config) => {
        config.headers['Content-Type'] = 'application/json'
        if (this.isLoggedIn) {
          config.headers['Authorization'] = 'Token ' + this.$store.getters.authToken
        }
        return config
      })

      this.$axios.interceptors.response.use(
          response => {
            return Promise.resolve(response);
          },
          this.axiosResponseError
      )

      for (const method of ['request', 'delete', 'get', 'head', 'options', 'post', 'put', 'patch']) {
        this.$axios['$' + method] = function () {
          return this[method].apply(this, arguments).then(res => res && res.data)
        }
      }
    },
    axiosResponseError(error) {
      const {data} = error.response
      if (data) {
        const {message, messages, code} = data

        if (message) {
          error.message = message
        } else {
          error.message = messages.ko
        }
        error.code = code

        if (error.status === 401) {
          this.$store.commit('setUser', null)
          this.messages.error('사용자 인증 정보가 올바르지 않습니다.')
          this.redirectToLogin()
        } else if (error.status === 500) {
          this.messages.error("서버에 에러가 발생했습니다.")
        } else if (error.code === 'INVALID_ROLE') {
          this.$router.replace({name: 'invalid-role'})
        } else if (error.message) {
          this.messages.error(
              `${error.message} [${error.code}]`
          )
        }
      }
      return Promise.reject(error)
    },
  }
}