<template>
  <FsLightbox
      :toggler="trigger"
      :sources="imageSources"
      :types="types"
      :exitFullscreenOnClose="exitFullscreenOnClose"
  />
</template>

<script>
import FsLightbox from "fslightbox-vue";

export default {
  components: {
    FsLightbox
  },
  props: {
    trigger: {type: Boolean, default: false,},
    source: {type: [Array, String]},
    exitFullscreenOnClose: {type: Boolean, default: true}
  },
  computed: {
    imageSources() {
      if (!this.exists(this.source)) {
        return [
            require('@/assets/images/noImage.png')
        ]
      }
      return this._.castArray(this.source)
    },
    types() {
      return new Array(this.imageSources.length).fill('image')
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
</style>