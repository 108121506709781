import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import '@/plugins/components'
import '@/plugins/iview'
import '@/plugins/styles'
import '@/plugins/filters'

import lodash from 'lodash'
import axios from 'axios'
import moment from 'moment'

Vue.prototype._ = lodash
Vue.prototype.$moment = moment
Vue.prototype.$axios = axios.create()

Vue.config.productionTip = false

import mainMixin from '@/mixins/main'
import axiosMixin from '@/mixins/axios'
import routeMixin from '@/mixins/route'

Vue.mixin(mainMixin)
Vue.mixin(axiosMixin)
Vue.mixin(routeMixin)

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    this.initAxios()
  },
}).$mount('#app')
