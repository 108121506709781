<template>
  <Scaffold>
    <template #buttons>
      <Button @click="() => $router.back()">뒤로</Button>
      <Button type="primary" :loading="loading" @click="onCreate">추가</Button>
    </template>

    <div class="page-content">
      <CTable
          header-key="stepList"
          v-bind="tableBinding()"
          @reload="getData"
      >
        <template #action="{row, index}">
          <div class="r-gap-buttons">
            <Button :loading="loading" size="small"
                    :disabled="index === 0"
                    @click="() => onStepMove(index, -1)">
              <CIcon name="fa-chevron-up"></CIcon>
            </Button>
            <Button :loading="loading" size="small"
                    :disabled="index === items.length - 1"
                    @click="() => onStepMove(index, 1)">
              <CIcon name="fa-chevron-down"></CIcon>
            </Button>
            <Button size="small"
                    @click="() => $router.push({name: 'exam-list', params: {stepId: row.id}})"
            >문제 목록
            </Button>
            <Button size="small" type="warning"
                    @click="() => onEdit(row.id)">수정
            </Button>
            <Button :loading="loading" size="small" type="error" @click="() => onDelete(row.id)">삭제</Button>
          </div>
        </template>
      </CTable>
    </div>
  </Scaffold>
</template>

<script>
import {tableMixin} from "@/mixins/table";

export default {
  mixins: [tableMixin],
  props: ['lectureId'],
  data() {
    return {
      paging: false
    }
  },
  computed: {
    apiUrl() {
      return `lecture/${this.lectureId}/step`
    }
  },
  methods: {
    onCreate() {
      this.loading = true;
      const index = this.items.length
      this.$axios.$post(`lecture/${this.lectureId}/step`, {index: index}).then(res => {
        this.messages.success('클래스 회차가 추가되었습니다.')
        this.getData()
      }).finally(_ => {
        this.loading = false;
      })
    },
    onStepMove(index, n) {
      const s = this.items[index].id
      const t = this.items[index + n].id

      this.loading = true
      this.$axios.$post(`lecture/${this.lectureId}/step/${s}/index`, {target_id: t}).then(res => {
        this.getData()
      }).finally(_ => {
        this.loading = false
      })
    },
    onEdit(id) {
      this.$router.push({
        name: 'lecture-step-editor', params: {
          lectureId: this.lectureId,
          id: id
        }
      })
    },
    onDelete(id) {
      this.deleteConfirm(() => {
        this.loading = true
        this.$axios.delete(`lecture/step/${id}`).then(res => {
          this.getData()
        }).finally(() => {
          this.loading = false
        })
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
</style>
